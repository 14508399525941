
/**
 * 根据id数组获取各父级id
 * @param {*多个子id} childArr 
 * @param {**已经扁平化的数组} flatList 
 * @param {*父id名} PId 
 * @param {*id名} Id 
 * @returns 
 */
const getParentIdList = function (childArr, flatList, PId, Id, labelName) {
  let tree = []
  childArr.forEach(item => {
    tree.push(getParentIds(item, flatList, PId, Id, labelName))
  })
  return tree
}

/**
 * 根据id获取各父级id
 * @param {子元素id值} childId 
 * @param {*已经扁平化的数组} flatList 
 * @param {*父id名} PId 
 * @param {*id名} Id 
 * @returns 
 */
const getParentIds = function (childId, flatList, PId, Id, labelName) {
  if (!labelName) {
    labelName = Id
  }
  let arr = []
  flatList.forEach(item => {
    if (item[Id] == childId) {
      arr.unshift(item[labelName])
      if (item[PId]) {
        arr.unshift(...getParentIds(item[PId], flatList, PId, Id, labelName))
      }
    }
  })
  return arr
}

/**
 * 将数组扁平化
 * @param {*数组} list 
 * @param {*子数组名} childName 
 * @returns 
 */
const getFlatlist = function (list, childName) {
  let arr = []
  list.forEach(item => {
    let sitem = { ...item }
    delete sitem[childName]
    if (item[childName] && item[childName].length > 0) {
      arr = arr.concat(sitem, ...getFlatlist(item[childName], childName))
    } else {
      delete item[childName]
      arr.push(item)
    }
  })
  return arr
}



module.exports = {
  getFlatlist,
  getParentIdList,
  getParentIds
}