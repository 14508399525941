import { instance } from '@/utils/http.js'

// 智能组卷列表
export const getTestList = function (params) {
  return instance({
    url: '/api/v1/combination/smart_list',
    method: 'get',
    params
  })
}

// 智能组卷年级
export const getGrade = function (params) {
  return instance({
    url: '/api/v1/school/select_grade',
    method: 'get',
    params
  })
}

// 智能组卷科目
export const getSubject = function (params) {
  return instance({
    url: '/api/v1/public/teacher_subject_lst',
    method: 'get',
    params
  })
}

// 智能组卷 新增/指定组卷 提交
export const createPaper = function (data) {
  return instance({
    url: 'index.php/api/v1/combination/smart_create',
    method: 'post',
    data
  })
}
